<template>
  <el-menu class="main-nav" mode="horizontal" :default-active="activeLink" router>
    <el-menu-item class="main-nav__item" v-for="link in navLinks" :key="link.path" :index="link.path">
      {{ link.name }}
      <img v-if="link.name === '게시판' && isNewNoti" src="@/assets/images/notification.png" />
      <!-- <span v-if="link.beta">βeta</span> -->
      <span class="tag" v-if="link.path === '/sales'">Beta</span>
    </el-menu-item>
  </el-menu>
</template>

<script>
export default {
  computed: {
    activeLink() {
      return `/${this.$route.path.split('/')[1]}`;
    },

    studio() {
      return this.$store.getters['studio/studio'];
    },

    navLinks() {
      let links = [
        { path: '/schedule', name: '일정' },
        { path: '/lectures', name: '수업' },
        { path: '/users', name: '회원' },
        { path: '/staffs', name: '강사' },
        { path: '/products', name: '수강권' },
      ];

      const canViewAnyMessage = this.canViewSmsMessage || this.canViewAppPushMessage;
      const isRouteInAppPush = ['message-form', 'message-list', 'auto-message-list'].includes(this.$route.name);

      if (canViewAnyMessage) {
        links.push({ path: isRouteInAppPush ? '/message' : '/sms', name: '메시지' });
      }

      links.push({ path: '/boards', name: '게시판' });

      const isUseLocker = _.get(this.studio.policy, 'is_locker', 1);
      if (isUseLocker) {
        links.push({ path: '/locker', name: '락커' });
      }

      links.push({ path: '/settings', name: '설정' });

      if (this.isStudioOwner || this.canViewSales) {
        if (process.env.VUE_APP_ENV !== 'production') links.push({ path: '/sales-legacy', name: '매출' });
        links.push({ path: '/sales', name: '매출' });
      }

      return links;
    },

    isNewNoti() {
      return this.$store.getters['boardNotice/isNewNotice'];
    },
  },
};
</script>

<style lang="scss" scoped>
.main-nav {
  flex: 1;
  display: flex;
  border: none;
  overflow-x: auto;
  overflow-y: hidden;

  &__item {
    position: relative;

    img {
      position: absolute;
      right: 3px;
      top: 12px;
    }
    .tag {
      width: 32px;
      height: 16px;
      padding: 0 5px;
      border-radius: 8px;
      background-color: #e2f3fe;
      color: #31a0f8;
      font-size: 10px;
      font-weight: 500;
      margin-left: 4px;
      position: relative;
      /* top: -1px; */
    }
  }
}
</style>
