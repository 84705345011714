import store from '@store';
import Vue from 'vue';
import Router from 'vue-router';
import routes from './routes';

Vue.use(Router);

const router = new Router({
  mode: 'history',
  routes,
});

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(() => {});
};

const originalReplace = Router.prototype.replace;
Router.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(() => {});
};

router.beforeEach(async (to, from, next) => {
  const toUserDetailPage = to.name === 'user_detail';

  if (toUserDetailPage) {
    store.commit('pointHistory/SET_RESET');
  }

  if (to.name !== 'users' && !toUserDetailPage) {
    store.commit('members/SET_SELECTED_MEMBERS', null);
  }

  const authRequired = to.matched.some(route => route.meta.auth);
  const isLoggedIn = store.getters['auth/isLoggedIn'] && store.getters['auth/isUnifiedUser'];

  if (!authRequired) return next();
  if (isLoggedIn) return next();

  store.commit('auth/SET_ACCESS_TOKEN', null);
  store.commit('auth/SET_CURRENT_USER', null);
  next({ name: 'login' });
});

router.beforeResolve(async (to, from, next) => {
  try {
    for (const route of to.matched) {
      await new Promise((resolve, reject) => {
        // meta: beforeResolve 훅이 존재할 경우
        if (route.meta && route.meta.beforeResolve) {
          route.meta.beforeResolve(to, from, (...args) => {
            // next({...})
            if (args.length) {
              next(...args);
              reject(new Error('redirected'));

              // next()
            } else {
              resolve();
            }
          });
        } else {
          resolve();
        }
      });
    }
  } catch (error) {
    return;
  }

  next();
});

export default router;
