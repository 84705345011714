<template>
  <main>
    <div>
      <img class="top-image" src="@/assets/images/03_item/logo_full/200x20/login.svg" />
    </div>

    <div class="middle-container">
      <div class="title">계정 통합을 위해 아이디를 선택해 주세요.</div>
      <div class="sub-title">보다 편리한 이용을 위해 하나의 아이디로 통합됩니다.</div>

      <div class="content">
        <el-radio-group v-model="selectedStaffAccountId">
          <el-radio v-for="(account, index) in sameStaffAccounts" :key="index" :label="account.id">
            <div class="info">
              <span>{{ account.identity }}</span>
              (<span class="account-name">{{
                account.integrated_account_login_staff ? account.integrated_account_login_staff.name : ''
              }}</span
              >)
            </div>

            <img
              class="icon-checked"
              v-show="account.id === selectedStaffAccountId"
              :src="require('@icons/icon_checked.png')"
              alt="체크 아이콘"
            />
          </el-radio>
        </el-radio-group>

        <el-button type="text" class="btn-modal-open" @click="openDialog">계정 통합 자세히 보기</el-button>
      </div>

      <el-button type="primary" class="btn-submit" @click="selectSubmit">선택</el-button>
    </div>

    <el-dialog class="send-message" title="계정 통합" :visible="isDialogOpen" @close="closeDialog" top="0">
      <p>하나의 계정으로 보다 간편하게 스튜디오메이트를 이용할 수 있도록 로그인 정책이 개편되었습니다.</p>
      <br />
      <p>선택한 아이디를 제외한 모든 아이디는 자동 삭제되며, 선택한 아이디로 기존 서비스를 계속 이용할 수 있습니다.</p>
    </el-dialog>
  </main>
</template>

<script>
import utils from '@utils';

export default {
  data() {
    return {
      selectedStaffAccountId: null,
      isDialogOpen: false,
    };
  },

  created() {
    if (!this.sameStaffAccounts || !this.sameStaffAccounts.length) {
      this.$router.push('/login');
    }
  },

  computed: {
    sameStaffAccounts() {
      return this.$store.getters['auth/sameStaffAccounts'];
    },

    mobile() {
      const currentAccount = this.sameStaffAccounts.filter(account => account.id === this.selectedStaffAccountId)[0];
      return currentAccount.mobile;
    },
  },

  methods: {
    selectSubmit() {
      const params = {
        studio_id: utils.localStorage.get('studioId'),
        selected_staff_account_id: this.selectedStaffAccountId,
        mobile: this.mobile,
      };
      this.$store.dispatch('auth/updateUnifiedAccount', { ...params, vm: this });
    },

    openDialog() {
      this.isDialogOpen = true;
    },

    closeDialog() {
      this.isDialogOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/ .el-dialog {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 480px;
  max-height: 188px;

  .el-dialog__header,
  .el-dialog__body {
    padding: 0;
  }

  .el-dialog__header {
    padding: 16px 16px 10px;

    .el-dialog__title {
      font-weight: 500;
    }

    .el-dialog__headerbtn {
      top: 16px;
    }
  }

  .el-dialog__body {
    padding: 10px 16px 26px;
  }
}

.top-image {
  width: 100%;
}

.middle-container {
  margin: 80px auto;
  max-width: 450px;

  .title,
  .sub-title {
    color: $charcoal-grey;
  }

  .title {
    margin-bottom: 4px;
    font-size: 24px;
    font-weight: 700;
  }

  .sub-title {
    margin-bottom: 30px;
    font-size: 16px;
  }

  .content {
    @include flex(column, center, center);
    gap: 20px;

    .el-radio-group {
      @include flex(column, center, center);
      gap: 8px;
      width: 100%;

      .el-radio {
        @include flex(row, center, space-between);
        margin: 0;
        padding: 8px 16px;
        width: 100%;
        height: 48px;
        border-radius: 2px;
        border: 1px solid #e9ecef;

        /deep/ .el-radio__label {
          order: 1;
          padding: 0;
          font-size: 15px;
        }

        &.is-checked {
          border-color: $color-main;

          /deep/ .el-radio__label {
            @include flex(row, center, space-between);
            width: 100%;
            font-weight: 700;
          }
        }

        /deep/ .el-radio__input {
          order: 2;
          /** 기존 라디오가 아니라, 체크모양의 커스텀 아이콘으로 대체 */
          display: none;
        }

        .icon-checked {
          width: 12.8px;
        }
      }

      .account-name {
        display: inline-block;
        max-width: 280px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .btn-modal-open {
      margin-bottom: 30px;
      padding: 0;
      text-decoration: underline;
      font-size: 14px;
      font-weight: 400;
      color: #9fa2a5;
    }
  }

  .btn-submit {
    width: 100%;
    height: 48px;
    border: 0;

    &:disabled {
      background-color: $gray-400;
    }
  }
}
</style>
